import { useContext, useEffect, useState } from 'react';
import { BreadcrumbContext } from '../../Layout/Layout';
import { useNavigate } from "react-router-dom";
import AddCustomer from "../AddCustomer";
import axios from 'axios';
import { toast } from 'react-toastify';
import { getStateName } from '../../../atoms/StatesDropdown/states';
import { deleteSite, viewEditSite } from '../../Sites/Sites/sitesUtilities';
import { useSelector } from 'react-redux';

function ViewCustomer() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const selectedCustomerData = useSelector(state => state.customer.selectedCustomer);
  const [selectedCustomer, setSelectedCustomer] = useState(selectedCustomerData);
  const [countries, setCountries] = useState([]);
  const [sitesData, setSitesData] = useState(selectedCustomerData?.sites || []);
  const navigate = useNavigate();
  const selectedCustomerId = localStorage.getItem('selectedCustomerId');

  const getCustomerData = async () => {
    if(!selectedCustomerId) {
      navigate('/customers');
    };

    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: `/GetCustomerById/${selectedCustomerId}`,
          method: 'get',
      });
      
      setCurrentBreadcrumb([
        {
          "linkText": "All Customers",
          "path": "/customers"
        },
        {
          "linkText": response?.data?.customeR_FIRSTNAME,
          "path": "/customers/view"
        },
      ]);
      setSelectedCustomer(response?.data);
      setSitesData(response?.data?.sites)
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  const getCountries = async () => {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: '/Countries',
          method: 'get',
      });
      
      setCountries(response?.data);
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  const getSelectedCountryName = (countryCode) => {
    const selectedCountry =  countries.filter(country => country.countrY_ID === countryCode);
    console.log(selectedCountry[0]?.nicename)
    return selectedCountry[0]?.nicename || '';
  };

  const viewLocations = (selectedSite) => {
    localStorage.setItem('selectedSite', JSON.stringify(selectedSite));
    navigate('/customers/view/locations');
  }

  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "All Customers",
        "path": "/customers"
      },
      {
        "linkText": selectedCustomer?.customeR_FIRSTNAME,
        "path": "/customers/view"
      }
    ]);
    getCountries();
    !selectedCustomerData && getCustomerData();
  }, []);

  useEffect(() => {
    (!selectedCustomer || parseInt(selectedCustomerId) !== parseInt(selectedCustomer.customeR_ID)) && getCustomerData();
  }, [selectedCustomerId, selectedCustomer]);

  const renderSiteDetails = () => sitesData.map((site, siteIndex) => (
    <div className="view-customer-site">
      <div className="view-customer-site-info-container">
        <div className="view-customer-site-info">
          <div className="view-customer-site-label">Site Name</div>
          <div className="view-customer-site-value">{site.sitE_NAME}</div>
        </div>

        <div className="view-customer-site-card">
          <div className="view-customer-site-label">
            Locations
            <div className="view-customer-site-value">{site.locations || 2}</div>
          </div>
          <div className="view-customer-site-btn">
            <button onClick={() => viewLocations(site)}>
              <img src="/assets/customers/view/view.png"/>
            </button>
          </div>
        </div>

        <div className="view-customer-site-card">
          <div className="view-customer-site-label">
            Passes
            <div className="view-customer-site-value">{site.passes || 4}</div>
          </div>
          <div className="view-customer-site-btn">
            <button>
              <img src="/assets/customers/view/view.png"/>
            </button>
          </div>
        </div>
      </div>

      <div className="view-customer-site-actions">
        <button onClick={() => viewEditSite('edit', site, navigate)}>
          <img src="/assets/customers/view/edit.png"/>
        </button>

        <button onClick={() => deleteSite(site, getCustomerData)}>
          <img src="/assets/customers/view/delete.png"/>
        </button>
      </div>
    </div>
  ));

  return (
    <div className="view-customer">
      <div className="view-customer-details">
        <div className="view-customer-details-img">
          <img id="previewImg" src={selectedCustomer?.logO_IMAGE ? `data:image/jpeg;base64,${selectedCustomer?.logO_IMAGE}` : "/assets/customers/default-pic.png"}/>
        </div>
        <div className="view-customer-details-info">
          <div className="view-customer-details-info1">
            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Customer Name
              </div>
              <div className="view-customer-details-info-value">
                {selectedCustomer?.customeR_FIRSTNAME}
              </div>
            </div>

            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Email
              </div>
              <div className="view-customer-details-info-value">
                {selectedCustomer?.customeR_EMAIL}
              </div>
            </div>

            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Phone
              </div>
              <div className="view-customer-details-info-value">
                {selectedCustomer?.phonE_NUMBER}
              </div>
            </div>

            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Notes
              </div>
              <div className="view-customer-details-info-value view-customer-details-info-value-notes">
                {selectedCustomer?.notes}
              </div>
            </div>
          </div>
          <div className="view-customer-details-info2">
          <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Website
              </div>
              <div className="view-customer-details-info-value">
                {selectedCustomer?.websitE_URL}
              </div>
            </div>

            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Country
              </div>
              <div className="view-customer-details-info-value">
                {getSelectedCountryName(selectedCustomer?.countrY_ID)}
              </div>
            </div>

            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                State
              </div>
              <div className="view-customer-details-info-value">
                {getStateName(selectedCustomer?.statE_ID)?.statE_NAME}
              </div>
            </div>

            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Address
              </div>
              <div className="view-customer-details-info-value">
                {selectedCustomer?.addresS_LINE1}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="view-customer-sites">
        <div className="view-customer-heading">{selectedCustomer?.customeR_FIRSTNAME}</div>
        { renderSiteDetails() }
      </div>
    </div>
  )
};

export default ViewCustomer;