import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import InputTextfield from '../../../atoms/textfield';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { validateForm } from '../../../utilities/form';

function ForgotPasswordForm() {
    const navigate = useNavigate();
    const loginBtnRef = useRef(null);
    const [formData, setFormData] = useState({
        email: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm(formData, {email: /\S+@\S+\.\S+/});
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            // Form submission logic here
            try {
                loginBtnRef.current.classList.add("button-loading");
                const response = await axios.request({
                    baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
                    url: '/ForgetUserSendEmail',
                    method: 'post',
                    data: {
                        "USERID_LOGIN": formData.email,
                        "url": window.location.origin
                    }
                });
                toast("Please check your mailbox. Email sent for a password reset link  !")
                loginBtnRef.current.classList.remove("button-loading");
                
                navigate("/signin");
            }
            catch(e) {
                loginBtnRef.current.classList.remove("button-loading");
                toast('Password recovery failed, please try again after sometime.')
            }
        }
    };

    return (
        <div className="forgot-password-form-content">
            <div className="forgot-password-form-logo"></div>
            <div className="forgot-password-form-label">
                Password Recovery
            </div>
            <div className="forgot-password-form-label forgot-password-form-label-note">
                Please enter your registered email
            </div>
            <form onSubmit={handleSubmit}>
                <div className="input-container forgot-password-form-input">
                    <i className="fa fa-envelope icon"></i>
                    <InputTextfield type="email" name="email" placeholder="Email" error={errors.email} hideError={true} onChange={handleChange}/>
                </div>
                { errors.email && (
                    <div className="error-message">
                        {errors.email}
                    </div>
                )}
                
                <div className="forgot-password-form-btn forgot-password-form-recover-btn">
                    <button type="submit" ref={loginBtnRef}>
                        <span class="button__text">Recover</span>
                    </button>
                </div>
            </form>
            
            <div className="forgot-password-form-btn">
                <Link to="/signin">
                    <button>
                        Go Back
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default ForgotPasswordForm;