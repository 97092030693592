import { useContext, useEffect, useState } from 'react';
import { BreadcrumbContext } from '../../Layout/Layout';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import AddLocation from './../AddLocation/AddLocation';

function EditLocation() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const [selectedSite, setSelectedSite] = useState(null);
  const navigate = useNavigate();
  const selectedSiteId = localStorage.getItem('selectedSiteId');

  const getSiteData = async () => {
    if(!selectedSiteId) {
      navigate('/sites');
    };

    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: `/site/sites`,
          method: 'get',
      });
      
      const site = response?.data?.filter(site => parseInt(site.sitE_ID) === parseInt(selectedSiteId));
      setCurrentBreadcrumb([
        {
          "linkText": "Sites",
          "path": "/sites"
        },
        {
          "linkText": site && site[0].sitE_NAME,
          "path": "/customers/view/locations"
        },
        {
          "linkText": "Edit",
          "path": "/sites/edit"
        }
      ]);
      setSelectedSite(site && site[0]);
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };
  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "Sites",
        "path": "/sites"
      },
      {
        "linkText": "Edit",
        "path": "/sites/edit"
      }
    ]);
    getSiteData();
  }, []);

  return (
    <div className="edit-location">
      <AddLocation isEditSite={true} selectedSite={selectedSite}/>
    </div>
  )
};

export default EditLocation;