import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { BreadcrumbContext } from '../../Layout/Layout';
import TableConfig from './customersTableConfig.json';
import StatesDropdown from '../../../atoms/StatesDropdown';
import { customersAdded, customerSelected } from '../customerSlice';
import classNames from 'classnames';

function AllCustomers() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { header, rows, visibleColumns } = TableConfig;
  const [columnHeaders, setColumnHeaders] = useState(header);
  const customers = useSelector(state => state.customer.customers);
  const updateCustomers = useSelector(state => state.customer.updateCustomers);

  const [originalCustomersData, setOriginalCustomersData] = useState(customers);
  const [customersData, setCustomersData] = useState(customers);
  const [countries, setCountries] = useState([]);
  const [searchKey, setSearchKey] = useState(null);
  const [stateFilter, setStateFilter] = useState('');

  const getCountries = async () => {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: '/Countries',
          method: 'get',
      });
      
      setCountries(response?.data);
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  const getCustomersData = async () => {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: '/GetAllCustomers',
          method: 'get',
      });
      const data = response?.data?.map(data => ({
        ...data,
        'contact_Name': data.customerContacts && data.customerContacts.length ? data.customerContacts[0].contacT_NAME : '',
        'status': data.activE_IND ? 'Approved': 'Pending'
      }));
      dispatch(customersAdded(data));
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "All Customers",
        "path": "/customers"
      }
    ]);
    getCountries();
    return () => {}
  }, []);

  useEffect(() => {
    getCustomersData();
  }, [updateCustomers]);

  useEffect(() => {
    setOriginalCustomersData(customers);
    setCustomersData(customers);
  }, [customers]);

  const navigateToAddCustomer = () => navigate('/customers/add');

  const handleTableSearch = () => {
    if(!searchKey) return setCustomersData(originalCustomersData);

    const searchCustomersData = [];
    originalCustomersData.forEach((row) => {
      const { logO_IMAGE, ...restCustomerData } = row;
      Object.values(restCustomerData).toString().toLowerCase().includes(searchKey.toLowerCase()) && searchCustomersData.push(row);
    });
    setCustomersData(searchCustomersData);
  }

  const sortTable = (columnKey, colIndex) => {
    const columns = [...columnHeaders];
    let sortedCustomersData = [];
    if(columns[colIndex]?.ascSort) {
      columns[colIndex].ascSort = false;
      columns[colIndex].descSort = true;
      sortedCustomersData = customersData.slice().sort((a,b) => b[columnKey]?.toString()?.localeCompare(a[columnKey]));
    } else {
      columns[colIndex].ascSort = true;
      columns[colIndex].descSort = false;
      sortedCustomersData = customersData.slice().sort((a,b) => {
        return a[columnKey]?.toString()?.localeCompare(b[columnKey])
    });
    }
    setColumnHeaders([
      ...columns,
    ]);
    setCustomersData(sortedCustomersData);
  }

  const generateColumnHeaders = () => columnHeaders.map(({columnName, columnKey, isSortable, descSort, ascSort}, colIndex) => (
    <th>
      {
        isSortable ? (
          <div key={`col${colIndex}`} className='table-column' onClick={() => sortTable(columnKey, colIndex)}>
            {columnName}
            <span>
              <i className={classNames({"fa fa-sort-asc": true, "active": ascSort})} style={{height:'5px'}}></i>
              <i className={classNames({"fa fa-sort-desc": true, "active": descSort})}></i>
            </span>
            <i class="fa fa-info-circle" title="Sorting by Alphabetical order"></i>
          </div>
        ) :
        <>{columnName}</>
      }
    </th>
  ));

  const deleteCustomer = async (row) => {
    const { customeR_FIRSTNAME, customeR_EMAIL } = row;
    //eslint-disable-line
    if (window.confirm(`Do you like to delete the customer - ${customeR_FIRSTNAME} ${customeR_EMAIL}`)) {
      //eslint-disable-line
      try {
        const response = await axios.request({
            baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
            url: `/deleteCustomers/${row.customeR_ID}`,
            method: 'DELETE',
        });
        

        getCustomersData();
        toast(`Customer ${customeR_FIRSTNAME}-${customeR_EMAIL} deleted successfully !`)
      }
      catch(e) {
        toast('Something went wrong, please try again later.')
      }
    }
  }

  const viewEditCustomer = (opType, row) => {
    localStorage.setItem('selectedCustomerId', row.customeR_ID);
    dispatch(customerSelected(row));
    opType === 'edit' ? navigate(`/customers/edit`) : navigate(`/customers/view`);
  }

  const filterCustomerData = (filterByAttr, selectedValue) => {
    setStateFilter(selectedValue);
    if(!selectedValue) return setCustomersData(originalCustomersData);

    const searchCustomersData = originalCustomersData.filter(customer => parseInt(customer[filterByAttr]) === parseInt(selectedValue));
    setCustomersData(searchCustomersData);
  };

  const generateDataColumn = (key, value, row) => {
    if(key === "logO_IMAGE") return (
      <img src={row[key] ? `data:image/jpeg;base64,${row[key]}` : "/assets/customers/default-pic.png"} className="customers-grid-logo"/>
    );

    if(key === "actions") return (
      <div className="customers-grid-row-actions">
        <button onClick={() => viewEditCustomer('view', row)}>
          <img src="/assets/customers/view.png"/>
        </button>
        <button onClick={() => viewEditCustomer('edit', row)}>
          <img src="/assets/customers/edit.png"/>
        </button>
        <button onClick={() => deleteCustomer(row)}>
          <img src="/assets/customers/delete.png"/>
        </button>
      </div>
    );
    
    if(key === 'activE_IND') {
      if(value) return (
        <div className="customers-grid-status customers-grid-status-active">{'Approved'}</div>
      )

      return (
        <div className="customers-grid-status">{'Pending'}</div>
      )
    }

    if(key === 'sites') return row.sites.length || 0;

    return value;
  }

  const generateRows = () => customersData.map((row) => (
    <tr>
      {
        visibleColumns.map(key => (
          <td> { generateDataColumn(key, row[key], row) } </td>
        ))
      }
    </tr>
  ));

  const renderTableActions = () => (
    <div className="customers-grid-heading">
      <div>
        All Customers
      </div>
      <div className="customers-grid-actions">
        <input name="tableSearchKey" placeholder="Type in name, address or number to search" onChange={(e) => setSearchKey(e.target.value)}/>
        <button className="customers-grid-actions-search" onClick={handleTableSearch}>
          <img src="/assets/customers/search.png"/>
        </button>
        <button className="customers-grid-actions-add" onClick={navigateToAddCustomer}>
          <img src="/assets/customers/add.png"/>
        </button>
      </div>
    </div>
  );

  const clearSelectedFilter = () => {
    setStateFilter('');
    setCustomersData([...originalCustomersData]);
  }

  const renderTableFilters = () => (
    <div className="customers-filter">
      <label for="state">Filter by</label>
      <StatesDropdown label="State" value={stateFilter} onChange={(e) => filterCustomerData('statE_ID', e.target.value)} />
      <button className="customers-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>
    </div>
  )

  return (
    <div className="customers">
      { renderTableFilters() }

      { renderTableActions() }

      <div className="customers-grid">
        <table frame="hsides" rules="rows" className="customers-grid-datatable">
          <thead>
            { generateColumnHeaders() }
          </thead>
          <tbody>
            {
              generateRows()
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllCustomers;