import { useContext, useEffect, useState } from 'react';
import { BreadcrumbContext } from '../../Layout/Layout';
import { useNavigate } from "react-router-dom";
import AddCustomer from "../AddCustomer";
import axios from 'axios';
import { toast } from 'react-toastify';
import { getStateName } from '../../../atoms/StatesDropdown/states';

function ViewLocations() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [countries, setCountries] = useState([]);
  const { sitE_NAME } = JSON.parse(localStorage.getItem('selectedSite') || {});
  const [selectedSite, setSelectedSite] = useState(sitE_NAME);
  const [locationsData, setLocationsData] = useState([
    {
      name: 'WN-121',
      passes: 35
    },
    {
      name: 'WN-122',
      passes: 35
    },
    {
      name: 'WN-123',
      passes: 35
    },
    {
      name: 'WN-124',
      passes: 35
    }
  ]);
  const navigate = useNavigate();
  const selectedCustomerId = localStorage.getItem('selectedCustomerId');

  const getCustomerData = async () => {
    if(!selectedCustomerId) {
      navigate('/customers');
    };

    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: `/GetCustomerById/${selectedCustomerId}`,
          method: 'get',
      });
      
      setCurrentBreadcrumb([
        {
          "linkText": "All Customers",
          "path": "/customers"
        },
        {
          "linkText": response?.data?.customeR_FIRSTNAME,
          "path": "/customers/view"
        },
        {
          "linkText": selectedSite,
          "path": "/customers/view/locations"
        }
      ]);
      setSelectedCustomer(response?.data);
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  const getCountries = async () => {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: '/Countries',
          method: 'get',
      });
      
      setCountries(response?.data);
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  const getSelectedCountryName = (countryCode) => {
    const selectedCountry =  countries.filter(country => country.countrY_ID === countryCode);
    console.log(selectedCountry[0]?.nicename)
    return selectedCountry[0]?.nicename || '';
  }

  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "All Customers",
        "path": "/customers"
      },
      {
        "linkText": 'View',
        "path": "/customers/view"
      },
    ]);
    getCountries();
    getCustomerData();
  }, []);

  const renderSiteDetails = () => locationsData.map((location, locationindex) => (
    <div className="view-locations-site" key={`location${locationindex}`}>
      <div className="view-locations-site-info-container">
        <div className="view-locations-site-info">
          <div className="view-locations-site-label">Location-{locationindex+1}</div>
          <div className="view-locations-site-value">{location.name}</div>
        </div>

        <div className="view-locations-site-card">
          <div className="view-locations-site-label">
            Passes
            <div className="view-locations-site-value">{location.passes}</div>
          </div>
          <div className="view-locations-site-btn">
            <button>
              <img src="/assets/customers/view/view.png"/>
            </button>
          </div>
        </div>
      </div>

      <div className="view-locations-site-actions">
        <button>
          <img src="/assets/customers/view/edit.png"/>
        </button>

        <button>
          <img src="/assets/customers/view/delete.png"/>
        </button>
      </div>
    </div>
  ));

  return (
    <div className="view-locations">
      <div className="view-locations-details">
        <div className="view-locations-details-img">
          <img id="previewImg" src={selectedCustomer?.logO_IMAGE ? `data:image/jpeg;base64,${selectedCustomer?.logO_IMAGE}` : "/assets/customers/default-pic.png"}/>
        </div>
        <div className="view-locations-details-info">
          <div className="view-locations-details-info1">
            <div className="view-locations-details-info-row">
              <div className="view-locations-details-info-label">
                Customer Name
              </div>
              <div className="view-locations-details-info-value">
                {selectedCustomer?.customeR_FIRSTNAME}
              </div>
            </div>

            <div className="view-locations-details-info-row">
              <div className="view-locations-details-info-label">
                Email
              </div>
              <div className="view-locations-details-info-value">
                {selectedCustomer?.customeR_EMAIL}
              </div>
            </div>

            <div className="view-locations-details-info-row">
              <div className="view-locations-details-info-label">
                Phone
              </div>
              <div className="view-locations-details-info-value">
                {selectedCustomer?.phonE_NUMBER}
              </div>
            </div>

            <div className="view-locations-details-info-row">
              <div className="view-locations-details-info-label">
                Notes
              </div>
              <div className="view-locations-details-info-value view-customer-details-info-value-notes">
                {selectedCustomer?.notes}
              </div>
            </div>
          </div>
          <div className="view-locations-details-info2">
          <div className="view-locations-details-info-row">
              <div className="view-locations-details-info-label">
                Website
              </div>
              <div className="view-locations-details-info-value">
                {selectedCustomer?.websitE_URL}
              </div>
            </div>

            <div className="view-locations-details-info-row">
              <div className="view-locations-details-info-label">
                Country
              </div>
              <div className="view-locations-details-info-value">
                {getSelectedCountryName(selectedCustomer?.countrY_ID)}
              </div>
            </div>

            <div className="view-locations-details-info-row">
              <div className="view-locations-details-info-label">
                State
              </div>
              <div className="view-locations-details-info-value">
                {getStateName(selectedCustomer?.statE_ID)?.statE_NAME}
              </div>
            </div>

            <div className="view-locations-details-info-row">
              <div className="view-locations-details-info-label">
                Address
              </div>
              <div className="view-locations-details-info-value">
                {selectedCustomer?.addresS_LINE1}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="view-locations-sites">
        <div className="view-locations-heading">{selectedSite}</div>
        { renderSiteDetails() }
      </div>
    </div>
  )
};

export default ViewLocations;