import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BreadcrumbContext } from '../../Layout/Layout';
import TableConfig from './locationsTableConfig.json';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { locationsAdded } from '../locationsSlice';
import { deleteSite, viewEditSite } from './locationsUtilities';

function Locations() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const { header, rows, visibleColumns } = TableConfig;
  const [columnHeaders, setColumnHeaders] = useState(header);
  const dispatch = useDispatch();
  const sites = useSelector(state => state.site.sites);
  const updateSites = useSelector(state => state.site.updateSites);

  const [originalSitesData, setOriginalSitesData] = useState(rows);
  const [contactNameData, setContactNameData] = useState([]);
  const [sitesData, setSitesData] = useState(rows);
  const [customers, setCustomers] = useState([]);
  const [searchKey, setSearchKey] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});

  const getCutomers = async () => {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: '/site/siteCustomer',
          method: 'get',
      });
      setCustomers(response?.data);
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  const getSitesData = async () => {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: '/site/sites',
          method: 'get',
      });
      dispatch(locationsAdded(response?.data));      
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  const getContactName = (sites) => {
    let contacts = [];
    sites.forEach(site => {
      !contacts.includes(site.sitE_CONTACT) && contacts.push(site.sitE_CONTACT);
    });
    setContactNameData([...contacts]);
  }

  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "Customers",
        "path": "/customers"
      },
      {
        "linkText": "Locations",
        "path": "/locations"
      }
    ]);
    getCutomers();
    return () => {}
  }, []);

  useEffect(() => {
    getSitesData();
  }, [updateSites])

  useEffect(() => {
    //setSitesData(sites);
    //setOriginalSitesData(sites);
    getContactName(rows);
  }, [sites]);

  const navigateToAddSite = () => {}; //navigate('/locations/add');

  const handleTableSearch = () => {
    if(!searchKey) return setSitesData(originalSitesData);

    const searchCustomersData = [];
    originalSitesData.forEach((row) => {
      const { logO_IMAGE, ...restCustomerData } = row;
      Object.values(restCustomerData)?.toString().toLowerCase().includes(searchKey.toLowerCase()) && searchCustomersData.push(row);
    });
    setSitesData(searchCustomersData);
  }

  const sortTable = (columnKey, colIndex) => {
    const columns = [...columnHeaders];
    let sortedSitesData = [];
    if(columns[colIndex]?.ascSort) {
      columns[colIndex].ascSort = false;
      columns[colIndex].descSort = true;
      sortedSitesData = sitesData.slice().sort((a,b) => b[columnKey]?.toString()?.localeCompare(a[columnKey]));
    } else {
      columns[colIndex].ascSort = true;
      columns[colIndex].descSort = false;
      sortedSitesData = sitesData.slice().sort((a,b) => a[columnKey]?.toString()?.localeCompare(b[columnKey]));
    }
    setColumnHeaders([
      ...columns,
    ]);
    setSitesData(sortedSitesData);
  }

  const generateColumnHeaders = () => columnHeaders.map(({columnName, columnKey, isSortable, descSort, ascSort}, colIndex) => (
    <th>
      {
        isSortable ? (
          <div key={`col${colIndex}`} className='table-column' onClick={() => sortTable(columnKey, colIndex)}>
            {columnName}
            <span>
              <i className={classNames({"fa fa-sort-asc": true, "active": ascSort})} style={{height:'5px'}}></i>
              <i className={classNames({"fa fa-sort-desc": true, "active": descSort})}></i>
            </span>
            <i class="fa fa-info-circle" title="Sorting by Alphabetical order"></i>
          </div>
        ) :
        <>{columnName}</>
      }
    </th>
  ));

  const clearSelectedFilter = () => {
    setSelectedFilters({
      sitE_ID: '',
      customeR_ID: '',
      sitE_CONTACT: ''
    });
    setSitesData([...originalSitesData]);
  }

  const filterCustomerData = (filterByAttr, selectedValue) => {
    setSelectedFilters({
      [filterByAttr]: selectedValue
    });

    if(!selectedValue) return setSitesData(originalSitesData);

    const searchCustomersData = [];
    originalSitesData.forEach((row) => {
      row[filterByAttr]?.toString()?.includes(selectedValue) && searchCustomersData.push(row);
    });
    setSitesData(searchCustomersData);
  };

  const generateDataColumn = (key, value, row) => {
    if(key === "actions") return (
      <div className="locations-grid-row-actions">
        <button onClick={() => viewEditSite('view', row, navigate)}>
          <img src="/assets/customers/view.png"/>
        </button>
        <button onClick={() => viewEditSite('edit', row, navigate)}>
          <img src="/assets/customers/edit.png"/>
        </button>
        <button onClick={() => deleteSite(row, getSitesData)}>
          <img src="/assets/customers/delete.png"/>
        </button>
      </div>
    );

    if(key === 'locations') return 20;

    if(key === 'customeR_NAME') return row.customeR_NAME; // getCustomerName(row.customeR_ID || 41);

    return value;
  }

  const generateRows = () => sitesData.map((row) => (
    <tr>
      {
        visibleColumns.map(key => (
          <td> { generateDataColumn(key, row[key], row) } </td>
        ))
      }
    </tr>
  ));

  const renderTableActions = () => (
    <div className="locations-grid-heading">
      <div>
        Locations
      </div>
      <div className="locations-grid-actions">
        <input name="tableSearchKey" placeholder="Type in any text or number value to search" onChange={(e) => setSearchKey(e.target.value)}/>
        <button className="locations-grid-actions-search" onClick={handleTableSearch}>
          <img src="/assets/customers/search.png"/>
        </button>
        <button className="locations-grid-actions-add" onClick={navigateToAddSite}>
          <img src="/assets/customers/add.png"/>
        </button>
      </div>
    </div>
  );

  const renderTableFilters = () => (
    <div className="locations-filter">
      <label for="state">Filter by</label>
      <select name="siteFilter" value={selectedFilters.sitE_ID} id="state" onChange={(e) => filterCustomerData('sitE_ID', e.target.value)}>
        <option value="">Locations</option>
        {
          originalSitesData.map(site => (
            <option value={site.sitE_ID}>{site.locatioN_NAME}</option>
          ))
        }
      </select>
      <label for="state">Filter by</label>
      <select name="siteFilter" value={selectedFilters.sitE_ID} id="state" onChange={(e) => filterCustomerData('sitE_ID', e.target.value)}>
        <option value="">Site Name</option>
        {
          originalSitesData.map(site => (
            <option value={site.sitE_ID}>{site.sitE_NAME}</option>
          ))
        }
      </select>
      <label for="state">Filter by</label>
      <select name="customerFilter" id="customer" value={selectedFilters.customeR_ID} onChange={(e) => filterCustomerData('customeR_ID', e.target.value)}>
        <option value="">Customer Name</option>
        {
          customers.map(customer => (
            <option value={customer.customeR_ID}>{customer.customeR_NAME}</option>
          ))
        }
      </select>
      <label for="state">Filter by</label>
      <select name="contactFilter" id="contact" value={selectedFilters.sitE_CONTACT} onChange={(e) => filterCustomerData('sitE_CONTACT', e.target.value)}>
        <option value="">Contact Name</option>
        {
          contactNameData.map(name => (
            <option value={name}>{name}</option>
          ))
        }
      </select>
      <button className="locations-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>
    </div>
  );

  return (
    <div className="sites">
      { renderTableFilters() }

      { renderTableActions() }

      <div className="locations-grid">
        <table border="1" frame="hsides" rules="rows" className="locations-grid-datatable">
          <thead>
            { generateColumnHeaders() }
          </thead>
          <tbody>
            {
              generateRows()
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Locations;