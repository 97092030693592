import { Link } from 'react-router-dom';
import { useState } from 'react';
import { validateForm } from '../../utilities/form';
import InputTextfield from '../../atoms/textfield';
import classNames from 'classnames';

function OTP_Form() {
  const [formData, setFormData] = useState({
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  });

  const formFields = [
    { name: 'otp1', type: 'number' },
    { name: 'otp2', type: 'number' },
    { name: 'otp3', type: 'number' },
    { name: 'otp4', type: 'number' },
    { name: 'otp5', type: 'number' },
    { name: 'otp6', type: 'number' },
  ];

  const [errors, setErrors] = useState({});

  const generateForm = (formFields, handleChange, classname) => formFields.map(({name, type, placeholder}, index) => (
    <>
      <div className={classNames(classname, `${classname}-${name}`)}>
        <InputTextfield type={type} name={name} placeholder={placeholder} onChange={handleChange}/>
      </div>
      {index !== formFields.length-1 && (
        <span className="otp-form-input-separator">-</span>
      )}
    </>
  ));

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value,
      });
      const newErrors = validateForm(formData);
      setErrors(newErrors);
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      const newErrors = validateForm(formData);
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
          // Form submission logic here
          console.log('Form submitted successfully!');
      } else {
          console.log('Form submission failed due to validation errors.');
      }
  };
  return (
    <div className="otp-form-content">
        <div className="otp-form-logo"></div>
        <div className="otp-form-label">
          Enter Verification Code
        </div>
        <div className="otp-form-label otp-form-label-note">
          We've sent a code to example@gmail.com
        </div>
        <form onSubmit={handleSubmit}>
          <div className="otp-form-inputs-container">
            { generateForm(formFields, handleChange, 'otp-form-input') }
          </div>
          {errors && Object.keys(errors).length && (
            <div className="error-message">
                Please enter all OTP numbers
            </div>
          )}
          <div className="otp-form-resend-otp">
            Didn't get a code? <Link to="/">Click to resend</Link>
          </div>
          <div className="otp-form-btn otp-form-login-btn">
            <button type="submit">Verify</button>
          </div>
        </form>
    </div>
  );
};

export default OTP_Form;