import { useContext } from 'react'
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { BreadcrumbContext } from '../../components/Layout/Layout';

function Sidenav({
  menus,
  ...props
}) {
  const  { currentBreadcrumb } = useContext(BreadcrumbContext);

  const isActiveLink = (linkName) => {
    //console.log(linkName, currentBreadcrumb, currentBreadcrumb && linkName?.toLowerCase().includes(currentBreadcrumb[0].linkText?.toLowerCase()));
    //return currentBreadcrumb ? linkName?.toLowerCase().includes(currentBreadcrumb[0].linkText?.toLowerCase()) : false;
    return window.location.pathname.toLowerCase().includes(linkName?.toLowerCase());
  }

  const toggleDropdownMenu = (event) => {
      const target = event.target;
        target.classList.toggle("active");
        var dropdownContent = target.nextElementSibling;
        if(!dropdownContent) return;
        if (dropdownContent.style.display === "block") {
          target.querySelector('.dropdown-close').style.display = "none";
          target.querySelector('.dropdown-open').style.display = "block";
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
          target.querySelector('.dropdown-close').style.display = "none";
          target.querySelector('.dropdown-open').style.display = "block";
        }
  };

  const generateMenu = () => menus.map((menuItem) => {
      return menuItem.submenu ? (
        <>
          <button className="dropdown-btn" onClick={(event) => toggleDropdownMenu(event)}>
            <img className="sidenav-icons" src={menuItem.iconPath}/>
            {menuItem.name}
            <i class="sidenav-icons-arrow dropdown-close fa fa-angle-right"></i>
            <i class="sidenav-icons-arrow dropdown-open fa fa-angle-down" style={{display: 'none'}}></i>
          </button>
          <div className="dropdown-container">
            {
              menuItem.submenu.map(submenu => (
                <Link to={submenu.path} className={classNames({'sidenav-active-link': isActiveLink(submenu.name)})}>{submenu.name}</Link>
              ))
            }
          </div>
        </>
      ) : (
        <Link to={menuItem.path} className={classNames({'sidenav-active-menu': isActiveLink(menuItem.name)})}>
          <img className="sidenav-icons" src={menuItem.iconPath}/>
          {menuItem.name}
        </Link>
      )
    });
   // ../../assets/sidenav/activity.png
  return (
    <div className="sidenav">
      <div className="sidenav-heading">Navigation</div>
      { generateMenu() }
    </div>
  );
};

export default Sidenav;