import { useContext, useEffect, useState } from 'react';
import { BreadcrumbContext } from '../../Layout/Layout';
import { useNavigate } from "react-router-dom";
import AddCustomer from "../AddCustomer";
import axios from 'axios';
import { toast } from 'react-toastify';

function EditCustomer() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const navigate = useNavigate();
  const selectedCustomerId = localStorage.getItem('selectedCustomerId');

  const getCustomerData = async () => {
    if(!selectedCustomerId) {
      navigate('/customers');
    };

    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: `/GetCustomerById/${selectedCustomerId}`,
          method: 'get',
      });
      
      setCurrentBreadcrumb([
        {
          "linkText": "All Customers",
          "path": "/customers"
        },
        {
          "linkText": response?.data?.customeR_FIRSTNAME,
          "path": "/customers/view"
        },
        {
          "linkText": "Edit",
          "path": "/customers/edit"
        }
      ]);
      setSelectedCustomer(response?.data);
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };
  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "All Customers",
        "path": "/customers"
      },
      {
        "linkText": "Edit",
        "path": "/customers/edit"
      }
    ]);
    getCustomerData();
  }, []);

  return (
    <div className="edit-customer">
      <AddCustomer isEditCustomer={true} selectedCustomer={selectedCustomer}/>
    </div>
  )
};

export default EditCustomer;