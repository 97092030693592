
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Signin from './components/Signin';
import Signup from './components/Signup';
import OTP from './components/OTP_validation';
import Dashboard from "./components/Dashboard";
import Layout from './components/Layout';

/** Customers screens */
import AllCustomers from "./components/Customers/AllCustomers";
import AddCustomer from "./components/Customers/AddCustomer";
import EditCustomer from "./components/Customers/EditCustomer";
import ViewCustomer from "./components/Customers/ViewCustomer";
import ViewLocations from "./components/Customers/ViewLocations";

/** Sites screens */
import Sites from "./components/Sites/Sites";
import AddSite from "./components/Sites/AddSite";
import EditSite from "./components/Sites/EditSite";

/** Locations screens */
import Locations from "./components/Locations/Locations";
import AddLocation from "./components/Locations/AddLocation";
import EditLocation from "./components/Locations/EditLocation";

import RecoverPassword from "./components/ForgotPassword/RecoveryPassword";
import SetPassword from "./components/ForgotPassword/SetPassword";
import 'react-toastify/dist/ReactToastify.min.css';
import { store } from "./store/store";
import { Provider } from "react-redux";
import './App.css';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Routes>
          <Route exact element={<Signin/>} path="/" />
          <Route exact element={<Signin/>} path="/signin" />
          <Route exact element={<Signup/>} path="/signup" />
          <Route exact element={<OTP/>} path="/otp" />
          <Route exact element={<RecoverPassword/>} path="/forgotpassword" />
          <Route exact element={<SetPassword/>} path="/login/resetpassword" />
          <Route element={<Layout />}>
            <Route exact element={<Dashboard/>} path="/dashboard" />
            <Route exact element={<AllCustomers/>} path="/customers" />
            <Route exact element={<AddCustomer/>} path="/customers/add" />
            <Route exact element={<EditCustomer/>} path="/customers/edit" />
            <Route exact element={<ViewCustomer/>} path="/customers/view" />
            <Route exact element={<Sites/>} path="/sites" />
            <Route exact element={<AddSite/>} path="/sites/add" />
            <Route exact element={<EditSite/>} path="/sites/edit" />
            <Route exact element={<ViewLocations/>} path="/customers/view/locations" />
            <Route exact element={<Locations/>} path="/locations" />
            <Route exact element={<AddLocation/>} path="/locations/add" />
            <Route exact element={<EditLocation/>} path="/locations/edit" />
            <Route exact element={<Dashboard/>} path="*" />
          </Route>
        </Routes>
        <ToastContainer />
      </Provider>
    </div>
  );
}



export default App;
