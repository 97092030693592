import { useEffect, useState } from 'react';
import axios from 'axios';

function CountryDropdown({
  isCountryPhoneCodeDropdown,
  onChange,
  name,
  defaultValue=13,
  placeholder="Select Country"
}) {
  const [countries, setCountries] = useState([]);

  const getCountries = async () => {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: '/Countries',
          method: 'get',
      });
      setCountries(response?.data);
    }
    catch(e) {
      console.log('Something went wrong with country dropdown, please try again later.')
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <select
      value={defaultValue}
      name={name}
      className='country-dropdown'
      onChange={onChange}>
        <option value="">{placeholder}</option>
      {
        isCountryPhoneCodeDropdown ? (
          countries.map(country => (
            <option value={country.countrY_ID}>+{country.countrY_ID}</option>
          ))
        ) : (
          countries.map(country => (
            <option value={country.countrY_ID}>{country.nicename}</option>
          ))
        )
      }
    </select>
  );
};

export default CountryDropdown;