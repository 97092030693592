import { useContext, useEffect } from 'react';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import InputTextfield from '../../../atoms/textfield';
import { validateForm } from '../../../utilities/form';
import { BreadcrumbContext } from '../../Layout/Layout';
import ToggleSwitch from '../../../atoms/Switch';
import StatesDropdown from '../../../atoms/StatesDropdown';
import { useDispatch } from 'react-redux';
import { customersUpdated } from '../customerSlice';

function AddCustomer({
  isEditCustomer,
  selectedCustomer
}) {
    const submitBtnRef = useRef(null);
    const dispatch = useDispatch();
    const editCustomer = isEditCustomer || window.location.pathname.includes('/customers/edit');
    const navigate = useNavigate();
    const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
    const [countries, setCountries] = useState([]);
    const [selectedLogoFileName, setSelectedLogoFileName] = useState('');
    const [contactPersonCount, setContactPersonCount] = useState(1);
    const [contactPersonArr, setContactPersonArr] = useState([{
      contacT_NAME: '',
      contacT_EMAIL: '',
      contacT_ROLE: '',
      contacT_PHONE_NUMBER: '',
      CONTACT_PHONE_NUMBER_CODE: 61
    }]);
    const [customerPhoneCountryCode, setCustomerPhoneCountryCode] = useState(61);
    const [formData, setFormData] = useState({
      ...selectedCustomer,
      logO_IMAGE: '',
      activE_IND: selectedCustomer?.activE_IND || 0,
      customeR_EMAIL: selectedCustomer?.customeR_EMAIL || '',  
      customeR_FIRSTNAME: selectedCustomer?.customeR_FIRSTNAME || '',
      phonE_NUMBER: selectedCustomer?.phonE_NUMBER || '',
      countrY_ID: selectedCustomer?.countrY_ID || 13,
      statE_ID: selectedCustomer?.statE_ID || '',
      addresS_LINE1:  selectedCustomer?.addresS_LINE1 || '',
      websitE_URL:  selectedCustomer?.websitE_URL || '',
      notes:  selectedCustomer?.notes || '',
    });

    const [errors, setErrors] = useState({});
    let payloadFormData = new FormData();

    const getCountries = async () => {
      try {
        const response = await axios.request({
            baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
            url: '/Countries',
            method: 'get',
        });
        
        setCountries(response?.data);
      }
      catch(e) {
        toast('Something went wrong, please try again later.')
      }
    };

    useEffect(() => {
      !editCustomer && setCurrentBreadcrumb([
        {
          "linkText": "All Customers",
          "path": "/customers"
        },
        {
          "linkText": "Add New",
          "path": "/customers/add"
        }
      ]);
      getCountries();
    }, []);

    const initializeCustomerFormData = (selectedCustomerData) => {
      if(!selectedCustomerData) return;
      const {
        createD_BY,
        updateD_BY,
        creatioN_DATE,
        updatE_DATE,
        logO_NAME,
        customerContacts,
        ...selectedCustomer
      } = selectedCustomerData;

      setFormData({
        ...selectedCustomer,
        logO_IMAGE: '',
        activE_IND: selectedCustomer?.activE_IND || 0,
        customeR_EMAIL: selectedCustomer?.customeR_EMAIL || '',  
        customeR_FIRSTNAME: selectedCustomer?.customeR_FIRSTNAME || '',
        phonE_NUMBER: selectedCustomer?.phonE_NUMBER || '',
        countrY_ID: selectedCustomer?.countrY_ID || 13,
        statE_ID: selectedCustomer?.statE_ID || '',
        addresS_LINE1:  selectedCustomer?.addresS_LINE1 || '',
        websitE_URL:  selectedCustomer?.websitE_URL || '',
        notes:  selectedCustomer?.notes || '',
      });

      if(!customerContacts || !customerContacts.length) return;

      const contacts = customerContacts.map(contact => ({
        ...contact,
        CONTACT_PHONE_NUMBER_CODE: contact?.contacT_PHONE_NUMBER ? contact?.contacT_PHONE_NUMBER?.substr(1,2): 61
      }));
      setContactPersonArr(contacts)
    }

    useEffect(() => {
      if(!selectedCustomer) return;
      editCustomer && initializeCustomerFormData(selectedCustomer);
      setCustomerPhoneCountryCode(selectedCustomer?.phonE_NUMBER ? selectedCustomer?.phonE_NUMBER?.substr(1,2): 61);
    }, selectedCustomer);

    const handleContactPersonCountChange = () => {
      const contacts = [... contactPersonArr];
      if (contacts.length > 3) return;
      contacts.push({
        contacT_NAME: '',
        contacT_EMAIL: '',
        contacT_ROLE: '',
        contacT_PHONE_NUMBER: '',
        CONTACT_PHONE_NUMBER_CODE: 61
      })
      setContactPersonArr([...contacts]);
    }

    const handleContactPersonDetailsChange = (personIndex, fieldName, changedValue) => {
      const contacts = [... contactPersonArr];
      contacts[personIndex][fieldName] = changedValue;
      setContactPersonArr([...contacts]);
    }

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        let changedValue = value;
        if(type === 'checkbox') {
          changedValue = e.target.checked ? 1 : 0;
        }

        setFormData({
            ...formData,
            [name]: changedValue,
        });
    };

    const handleImageChange = (e, fieldName) => {
      const [file] = e.target.files;
      if(!file) return;

      document.getElementById('previewImgError').innerHTML = "";
      if(file.size > 1048576) {
        document.getElementById('previewImgError').innerHTML = "File size is more than 1MB."
        return;
      }

      setSelectedLogoFileName(file.name);
      if (file) {
        document.getElementById('previewImg').src = URL.createObjectURL(file)
      }

      setFormData({
          ...formData,
          [fieldName]: file,
      });
    }

    const clearFormData = () => {
      for (var key of payloadFormData.keys()) {
        payloadFormData.delete(key)
      }
    }

    const prepareContactPersonsData = () => {
      const data = contactPersonArr.map(contact => ({
        ...contact,
        contacT_PHONE_NUMBER: contact.contacT_PHONE_NUMBER ? '+' + contact.CONTACT_PHONE_NUMBER_CODE + contact.contacT_PHONE_NUMBER : ''
      }));
      return data;
    }

    const uploadLogoImage = async (custId, logO_IMAGE) => {
      try {
        let payloadFormData = new FormData();
        payloadFormData.append('customeR_FIRSTNAME', formData.customeR_FIRSTNAME);
        payloadFormData.append('customeR_FIRSTNAME', formData.customeR_FIRSTNAME);
        payloadFormData.append('logO_IMAGE', logO_IMAGE);
        submitBtnRef.current.classList.add("button-loading");
        setTimeout(async() => {
          const response = await axios.request({
              baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
              url: editCustomer ? `/updateCustomer/image/${selectedCustomer?.customeR_ID}` : `/updateCustomer/image/${custId}`,
              method: 'put',
              headers: {
                'Content-Type': `multipart/form-data`,
              },
              data: payloadFormData
          });
          dispatch(customersUpdated());
          editCustomer ? toast("Custormer details updated successfully.") : toast("Custormer added successfully.");
          clearFormData();
          submitBtnRef.current.classList.remove("button-loading");
          navigate("/customers");
        }, 1000);
      }
      catch(e) {
          submitBtnRef.current.classList.remove("button-loading");
          toast('Something went wrong, please try again later.');
      }
    }
    

    const handleSubmit = async (e) => {
      e.preventDefault();
      formData.customerContacts = prepareContactPersonsData();
      const {
        customerContacts,
        logO_IMAGE,
        customeR_EMAIL,  
        customeR_FIRSTNAME,
        phonE_NUMBER,
        statE_ID,
        addresS_LINE1,
        websitE_URL,
      } = formData;
      
      const formInputs = {
        customerContacts,
        logO_IMAGE: logO_IMAGE || selectedCustomer?.logO_IMAGE,
        customeR_EMAIL,  
        customeR_FIRSTNAME,
        phonE_NUMBER,
        statE_ID,
        addresS_LINE1,
        websitE_URL,
      }
      const newErrors = validateForm(formInputs, {email: /\S+@\S+\.\S+/});
      const customers = customerContacts.map(cust => {
        const {
          contacT_EMAIL,
          contacT_NAME,
          contacT_PHONE_NUMBER,
          contacT_ROLE
        } = cust;
        return {
          contacT_EMAIL,
          contacT_NAME,
          contacT_PHONE_NUMBER,
          contacT_ROLE
        };
      })
      const customerContactsErrors = validateForm(customers[0]);
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0 && Object.keys(customerContactsErrors).length === 0) {
        const logO_IMAGE = formData.logO_IMAGE;
        delete formData.logO_IMAGE;
        const phone = formData?.phonE_NUMBER?.includes('+') ? formData?.phonE_NUMBER?.substr(3) : formData?.phonE_NUMBER;
        formData.phonE_NUMBER = phone ? `${ customerPhoneCountryCode ? '+' + customerPhoneCountryCode + phone : phone}` : '';
        try {
              submitBtnRef.current.classList.add("button-loading");
              const response = await axios.request({
                  baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
                  url: editCustomer ? `/updateCustomer/${selectedCustomer?.customeR_ID}` : '/addCustomers',
                  method: 'post',
                  data: formData
              });
              if(logO_IMAGE) {
                uploadLogoImage(response?.data, logO_IMAGE);
                return;
              }
              dispatch(customersUpdated());
              editCustomer ? toast("Custormer details updated successfully.") : toast("Custormer added successfully.");
              submitBtnRef.current.classList.remove("button-loading");
              
              navigate("/customers");
          }
          catch(e) {
              submitBtnRef.current.classList.remove("button-loading");
              toast('Something went wrong, please try again later.');
          }
      } else {
        toast('All the input fileds marked with * are mandatory to fill.');
      }
    };

    const renderContactPersonsDetails = () => {
      const contactPersons = [];
      for (let index = 0; index < contactPersonArr?.length; index++) {
        contactPersons.push(
          <>
            <div className="add-customer-form-input add-customer-form-input-dual">
                  <InputTextfield
                    type="text"
                    name="contacT_NAME"
                    hideError={true}
                    value={contactPersonArr[index].contacT_NAME}
                    placeholder={`Contact Person Name* ${index + 1}`}
                    onChange={(e) => handleContactPersonDetailsChange(index, 'contacT_NAME', e.target.value)}/>
                  <InputTextfield
                    type="text"
                    name="contacT_ROLE"
                    hideError={true}
                    value={contactPersonArr[index].contacT_ROLE}
                    placeholder="Role*"
                    onChange={(e) => handleContactPersonDetailsChange(index, 'contacT_ROLE', e.target.value)}/>
              </div>

              <div className="add-customer-form-input">
                  <InputTextfield
                    type="text"
                    name="contacT_EMAIL"
                    hideError={true}
                    value={contactPersonArr[index].contacT_EMAIL}
                    placeholder="Email*"
                    onChange={(e) => handleContactPersonDetailsChange(index, 'contacT_EMAIL', e.target.value)}/>
              </div>

              <div className="add-customer-form-input add-customer-form-input-phone">
                <select
                  onChange={(e) => handleContactPersonDetailsChange(index, 'CONTACT_PHONE_NUMBER_CODE', e.target.value)}>
                  {
                    countries.map((country, cIndex) => (
                      <option
                        key={`contacT_PHONE_NUMBER_Code${cIndex}`}
                        value={country.phonecode}
                        selected={parseInt(contactPersonArr[index]?.CONTACT_PHONE_NUMBER_CODE) === country.phonecode}
                      >{country.phonecode}</option>
                    ))
                  }
                </select>
                <InputTextfield
                  type="number"
                  name="contacT_PHONE_NUMBER"
                  hideError={true}
                  placeholder="Phone*"
                  value={contactPersonArr[index].contacT_PHONE_NUMBER?.includes('+') ? contactPersonArr[index].contacT_PHONE_NUMBER?.substr(3) : contactPersonArr[index].contacT_PHONE_NUMBER}
                  onChange={(e) => handleContactPersonDetailsChange(index, 'contacT_PHONE_NUMBER', e.target.value)}/>
              </div>
          </>
        )
      }

      return contactPersons;
    }

    return (
      <div className="add-customer">
        <div className="add-customer-heading">
          <div className="add-customer-heading-text">
            {editCustomer ? 'Edit' : 'Add New Customer'}
          </div>
          {
            editCustomer ? (
              <div className="edit-customer-switch">
                <label className="edit-customer-switch-label">
                  Status
                </label>
                Pending
                <ToggleSwitch name="activE_IND" onChange={handleChange} selected={formData?.activE_IND}/>
                Approved
              </div>
            ) : (
              <div></div>
            )
          }
        </div>
        <form onSubmit={handleSubmit}>
          <div className="add-customer-form">
            <div className="add-customer-form-content">
              <div className="add-customer-form-input">
                  <InputTextfield type="text" hideError={true} value={formData?.customeR_FIRSTNAME} name="customeR_FIRSTNAME" placeholder="Customer Name*" error={errors.customeR_FIRSTNAME} onChange={handleChange}/>
              </div>

              <div className="add-customer-form-input">
                  <InputTextfield type="text" hideError={true} value={formData?.customeR_EMAIL} name="customeR_EMAIL" placeholder="Email*" error={errors.customeR_EMAIL} onChange={handleChange}/>
              </div>

              <div className="add-customer-form-input add-customer-form-input-phone">
                <select onChange={(e) => setCustomerPhoneCountryCode(e.target.value)}>
                  {
                    countries.map((country, index) => (
                      <option key={`customerPhoneCountryCode${index}`} selected={parseInt(customerPhoneCountryCode) === country.phonecode} value={country.phonecode}>{country.phonecode}</option>
                    ))
                  }
                </select>
                <InputTextfield type="number" name="phonE_NUMBER" hideError={true} value={formData?.phonE_NUMBER?.includes('+') ? formData?.phonE_NUMBER?.substr(3) : formData?.phonE_NUMBER} placeholder="Phone*" error={errors.phonE_NUMBER} onChange={handleChange}/>
              </div>

              <div className="add-customer-form-input add-customer-form-input-select">
                <StatesDropdown value={formData?.statE_ID} error={errors.statE_ID} onChange={handleChange} />
              </div>

              <div className="add-customer-form-input">
                  <InputTextfield type="text" name="addresS_LINE1" hideError={true} value={formData?.addresS_LINE1} placeholder="Address*" error={errors.addresS_LINE1} onChange={handleChange}/>
              </div>

              <div className="add-customer-form-input">
                  <InputTextfield type="text" name="websitE_URL" hideError={true} value={formData?.websitE_URL} placeholder="Website URL*" error={errors.websitE_URL} onChange={handleChange}/>
              </div>

              <div className="add-customer-heading">
                Contact Person Details
              </div>
              { renderContactPersonsDetails() }
              <div className="add-customer-form-add-more" onClick={handleContactPersonCountChange}>
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                Add more
              </div>
            </div>

            <div className="add-customer-form-logo-content add-customer-form-content">
              <div className="add-customer-form-logo-control">
                <div className="add-customer-form-logo-control-img">
                  <img id="previewImg" src={selectedCustomer?.logO_IMAGE ? `data:image/jpeg;base64,${selectedCustomer?.logO_IMAGE}` : "/assets/customers/default-pic.png"}/>
                </div>
                <div>
                  <span>
                    Minimum logo size should be 200 x 200 pixels and maximum logo size should be 800 x 800 pixels. File size should be less than 1 MB
                  </span>
                  <div class="add-customer-form-btn add-customer-form-btn-select upload-btn-wrapper">
                    {selectedLogoFileName ? <div className="add-customer-form-logo-control-file-name">File selected: {selectedLogoFileName}</div> : <div></div> }
                    <div id="previewImgError" style={{color: 'red'}}></div>
                    <button class="custom-btn">Select file*</button>
                    <input type="file" name="logO_IMAGE" onChange={(e) => handleImageChange(e, 'logO_IMAGE')}/>
                  </div>
                </div>
              </div>
              <div className="add-customer-form-notes">
                <div className="add-customer-form-notes-title">Add Note</div>
                <textarea name="notes" value={formData?.notes} className="add-customer-form-notes-control" placeholder="Text here" onChange={handleChange}></textarea>
              </div>
            </div>
          </div>
            
            <div className="add-customer-form-btn add-customer-form-btn-submit">
                <button type="submit" ref={submitBtnRef}>
                    <span class="button__text">Save</span>
                </button>
            </div>
        </form>
      </div>
    );
};

export default AddCustomer;