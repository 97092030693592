import { useContext, useEffect, useState } from 'react';
import { BreadcrumbContext } from "../Layout/Layout";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { customersAdded } from '../Customers/customerSlice';
import { sitesAdded } from '../Sites/sitesSlice';
import axios from 'axios';

function Dashboard() {
    const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
    const { status } = {userType: 'admin', status: 1} || localStorage.getItem('userData');
    const [dashboardData, setDashboardData] = useState([]);
    const customers = useSelector(state => state.customer.customers);
    const sites = useSelector(state => state.site.sites);
    const dispatch = useDispatch()

    const getCustomersData = async () => {
      try {
        const response = await axios.request({
            baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
            url: '/GetAllCustomers',
            method: 'get',
        });
        const data = response?.data?.map(data => ({
          ...data,
          'contact_Name': data.customerContacts && data.customerContacts.length ? data.customerContacts[0].contacT_NAME : '',
          'status': data.activE_IND ? 'Approved': 'Pending'
        }));
        dispatch(customersAdded(data));
      }
      catch(e) {
      }
    };

    const getSitesData = async () => {
      try {
        const response = await axios.request({
            baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
            url: '/site/sites',
            method: 'get',
        });
        dispatch(sitesAdded(response?.data));
      }
      catch(e) {
      }
    };

    useEffect(() => {
      setCurrentBreadcrumb([
        {
          "linkText": "Home",
          "path": "/home"
        }
      ]);
      getCustomersData();
      getSitesData();
    }, []);

    useEffect(() => {
      setDashboardData([
        {
          name: 'Customer',
          count: customers?.length || 0,
          path: '/customers',
          imagePath: '/assets/dashboard/customers.png'
        },
        {
          name: 'Sites',
          count: sites?.length || 0,
          path: '/sites',
          imagePath: '/assets/dashboard/sites.png'
        },
        {
          name: 'Locations',
          count: 0,
          path: '/locations',
          imagePath: '/assets/dashboard/locations.png'
        },
        {
          name: 'Passes',
          count: 0,
          path: '/passes',
          imagePath: '/assets/dashboard/passes.png'
        }
      ]);
    }, [customers.length, sites.length]);

     

    const generateCards = () => dashboardData.map(data => (
      <div className="dashboard-card">
        <div className="dashboard-card-content">
          <div className="dashboard-card-details">
            <div className="dashboard-card-name">{data.name}</div>
            <div className="dashboard-card-count">{data.count}</div>
            <div className="dashboard-card-link">
              <Link to={data.path}>
                View All
              </Link>
          </div>
        </div>
        <div className="dashboard-card-img">
          <img src={data.imagePath}/>
        </div>
        </div>
        
      </div>
    ));

    return (
      <div className="dashboard">
        <div className="dashboard-title">Dashboard</div>
        <div className="dashboard-cards">
          { status ? generateCards() : null }
        </div>
      </div>
    );
};

export default Dashboard;