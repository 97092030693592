import classNames from "classnames";
function Input_Textfield({
    onChange,
    hideError,
    error,
    name,
    type,
    placeholder,
    className,
    refProp,
    value
}) {
    return (
        <>
            <input type={type} name={name} value={value} ref={refProp} placeholder={placeholder} onChange={onChange} className={classNames(className, {'input-error': error})}></input>
            { !hideError && error && (
                <span className="error-message">
                    {error}
                </span>
            )}
        </>
    );
};

export default Input_Textfield;